@import 'styles/layers.scss';

@layer uikit {
  .content {
    border-radius: 4px;
    padding: 20px;
    color: var(--white);
    background-color: var(--black13);
  }
}
